import { DriveItem } from '../components/Drives/ActionBar/types';

export const getValidatedItems = (
  currentArr: Array<DriveItem>,
  updatedArr: Array<DriveItem>,
  selected: boolean
) => {
  if (!selected) {
    return currentArr.filter(a => !updatedArr.find(i => a.id === i.id));
  }
  return currentArr.filter(a => !updatedArr.includes(a)).concat(updatedArr);
};

export default {
  getValidatedItems,
};
