import { createSlice } from '@reduxjs/toolkit';
import {
  DriveItem,
  DrivesAction,
  DrivesActionItems,
} from '../components/Drives/ActionBar/types';
import { getValidatedItems } from './drivesUtils';

export type DrivesSliceAction = Omit<DrivesAction, 'onClick'>;

const enabledProjectActions: Array<DrivesSliceAction> = [
  {
    type: DrivesActionItems.Move,
    enabled: true,
    disabled: false,
    icon: 'move',
  },
];

/**
 * @TODO Leaving here for testing purposes but before release need to update
 */
const disabledProjectActions: Array<DrivesSliceAction> = [
  {
    type: DrivesActionItems.Download,
    enabled: true,
    disabled: false,
    icon: 'download',
  },
  {
    type: DrivesActionItems.Delete,
    enabled: true,
    disabled: false,
    icon: 'trash',
  },
];

export const projectActions: Array<DrivesSliceAction> = [
  ...enabledProjectActions,
  ...disabledProjectActions,
];

type DrivesItemSelectedPayload = {
  arr: Array<DriveItem>;
  selected: boolean;
};

interface DrivesState {
  actionBarActive: boolean;
  actionBarActions: Array<DrivesSliceAction>;
  selectedProjects: Array<DriveItem>;
  selectedFolders: Array<DriveItem>;
  itemsSelected: number;
}

export const initialState: DrivesState = {
  actionBarActive: false,
  actionBarActions: [],
  selectedFolders: [],
  selectedProjects: [],
  itemsSelected: 0,
};

const drivesSlice = createSlice({
  name: 'drives',
  initialState,
  reducers: {
    setActions: (
      state,
      action: {
        payload: {
          actions: Array<DrivesAction>;
        };
      }
    ) => {
      return {
        ...state,
        actions: action.payload.actions,
      };
    },
    setProjects: (
      state: DrivesState,
      action: { payload: DrivesItemSelectedPayload }
    ) => {
      const { arr, selected } = action.payload;
      const selectedProjects = getValidatedItems(
        state.selectedProjects,
        arr,
        selected
      );
      const itemsSelected =
        selectedProjects.length + state.selectedFolders.length;
      return {
        ...state,
        actionBarActions: projectActions,
        actionBarActive:
          !!selectedProjects.length || !!state.selectedFolders.length,
        selectedProjects,
        itemsSelected,
      };
    },
    setFolders: (
      state: DrivesState,
      action: { payload: DrivesItemSelectedPayload }
    ) => {
      const { arr, selected } = action.payload;
      const selectedFolders = getValidatedItems(
        state.selectedFolders,
        arr,
        selected
      );
      const itemsSelected =
        selectedFolders.length + state.selectedProjects.length;
      return {
        ...state,
        actionBarActions: projectActions,
        actionBarActive:
          !!selectedFolders.length || !!state.selectedProjects.length,
        selectedFolders,
        itemsSelected,
      };
    },
    reset: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const { setActions, setProjects, setFolders, reset } =
  drivesSlice.actions;

export default drivesSlice;
