import { Reducer } from 'react';
import Prompts from '.';
import { getInitialState } from './PromptsContext';

const reducer: Reducer<Prompts.ContextState, Prompts.ContextActions> = (
  state,
  action
) => {
  switch (action.type) {
    case 'OpenAPIRequestKeyPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenCancelSubscriptionPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenUndoCancelSubscriptionPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenSubscriptionPaymentPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenSubscriptionTiersPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenSubscriptionUpgradeConfirmationPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenProjectDeletionPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenProjectRenamingPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenProjectRenamingPromptV2':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenProjectSharingPromptv2':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenProjectSharingTeamMemberBulkEditPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenProjectAccessDestructiveActionPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenMoveClipsToProjectPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenBatchDeleteClipsPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenDeleteClipPreviewPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenPhoneticLibraryImportPrompt':
      return {
        activePrompt: action.type,
      };
    case 'OpenCombineClipsPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenTeamMemberEditPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenTeamMemberInvitationPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenTeamMemberMaxSeatsReachedPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenTeamMemberAdjustSeatPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenTeamMemberInvitationCancellationPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenTeamMemberInvitationUpdatePrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenTeamMemberRemovalPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenTeamRequestSeatsPrompt':
      return {
        activePrompt: action.type,
      };
    case 'OpenActorListPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenActorListPromptV2':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenEntityChangedNotificationPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenFilterAvatarsPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenCopyClipsToProjectPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenReplacementsMigrationPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenReplacementsLibraryPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenProjectReplacementsPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenConfirmationPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenUnfavoriteAvatarsPrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenRemoveTeamVoicePrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenVoiceUpgradePrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'OpenFolderCreatePrompt':
      return {
        activePrompt: action.type,
        activePromptPayload: action.payload,
      };
    case 'CloseActivePrompt':
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return getInitialState();
    default:
      return state;
  }
};

export default reducer;
