/**
 * note icons with number prefixes have different variations in the design system
 * @see https://www.figma.com/file/TWgty8gcTip6JWbfKJigFb/WL-Styles-v1.0-Breve?type=design&node-id=3805-10187&mode=design&t=CCP5nfSLNAUphCNf-0
 * @note _1 is a stroke of width 1
 */
export enum IconType {
  'accessTime' = 'accessTime',
  'accessTimeV2' = 'accessTimeV2',
  'accessTimeFilled' = 'accessTimeFilled',
  'add' = 'add',
  'admin' = 'admin',
  'adminFilled' = 'adminFilled',
  'alignLeft01' = 'alignLeft01',
  'alignRight01' = 'alignRight01',
  'announcement' = 'announcement',
  'announcement01_simple' = 'announcement01_simple',
  'announcement01' = 'announcement01',
  'arrowCircle' = 'arrowCircle',
  'arrowLeft' = 'arrowLeft',
  'arrowRight' = 'arrowRight',
  'arrowUpRight' = 'arrowUpRight',
  'bankNote' = 'bankNote',
  'bankNote_1' = 'bankNote_1',
  'bell' = 'bell',
  'bookmark' = 'bookmark',
  'bookmarkFilled' = 'bookmarkFilled',
  'bookOpen01' = 'bookOpen01',
  'bookOpen02' = 'bookOpen02',
  'building07' = 'building07',
  'check' = 'check',
  'checkbox' = 'checkbox',
  'checkboxChecked' = 'checkboxChecked',
  'checkboxIndeterminate' = 'checkboxIndeterminate',
  'chevronDown' = 'chevronDown',
  'chevronLeft' = 'chevronLeft',
  'chevronRight' = 'chevronRight',
  'chevronUp' = 'chevronUp',
  'clear' = 'clear',
  'clipboardCheck' = 'clipboardCheck',
  'clockRewind' = 'clockRewind',
  'close' = 'close',
  'codeSquare' = 'codeSquare',
  'codeSquare_1' = 'codeSquare_1',
  'combineClips' = 'combineClips',
  'combineClipsFilled' = 'combineClipsFilled',
  'copy' = 'copy',
  'copyFilled' = 'copyFilled',
  'creditCard' = 'creditCard',
  'creditCard01' = 'creditCard01',
  'cues' = 'cues',
  'customerCare' = 'customerCare',
  'delete' = 'delete',
  'deleteFilled' = 'deleteFilled',
  'dotsGrid' = 'dotsGrid',
  'download' = 'download',
  'dragHandle' = 'dragHandle',
  'edit' = 'edit',
  'editFilled' = 'editFilled',
  'ex' = 'ex',
  'exclamation' = 'exclamation',
  'expand' = 'expand',
  'export' = 'export',
  'eye' = 'eye',
  'eyeOff' = 'eyeOff',
  'faceSmile' = 'faceSmile',
  'favorite' = 'favorite',
  'favoriteBordered' = 'favoriteBordered',
  'favoriteFilled' = 'favoriteFilled',
  'file02' = 'file02',
  'fileCheck' = 'fileCheck',
  'fileProject' = 'fileProject',
  'filterFunnel' = 'filterFunnel',
  'folder' = 'folder',
  'folderFilled' = 'folderFilled',
  'gamingPad01' = 'gamingPad01',
  'globe02' = 'globe02',
  'graduationHat01' = 'graduationHat01',
  'grid01' = 'grid01',
  'heart' = 'heart',
  'heartCircle' = 'heartCircle',
  'heartHand' = 'heartHand',
  'helpCircle' = 'helpCircle',
  'import' = 'import',
  'infoCircle' = 'infoCircle',
  'integrations' = 'integrations',
  'key' = 'key',
  'lightbulb' = 'lightbulb',
  'lightning01' = 'lightning01',
  'link' = 'link',
  'lock' = 'lock',
  'lock03' = 'lock03',
  'lockFilled' = 'lockFilled',
  'lockUnlocked' = 'lockUnlocked',
  'lockUnlockedFilled' = 'lockUnlockedFilled',
  'loop' = 'loop',
  'magicWand01' = 'magicWand01',
  'markerPin' = 'markerPin',
  'medicalCross' = 'medicalCross',
  'menu' = 'menu',
  'messageQuestionCircle' = 'messageQuestionCircle',
  'messageTextSquare02' = 'messageTextSquare02',
  'microphone01' = 'microphone01',
  'microphone01_1' = 'microphone01_1',
  'microphone02' = 'microphone02',
  'minus-circle' = 'minus-circle',
  'minus' = 'minus',
  'monitor' = 'monitor',
  'monitor02' = 'monitor02',
  'moreHoriz' = 'moreHoriz',
  'moreVert' = 'moreVert',
  'move' = 'move',
  'openInNew' = 'openInNew',
  'package' = 'package',
  'palette' = 'palette',
  'paperclip' = 'paperclip',
  'pause' = 'pause',
  'pauseFilled' = 'pauseFilled',
  'pencil02' = 'pencil02',
  'person' = 'person',
  'person_1' = 'person_1',
  'personFilled' = 'personFilled',
  'phone' = 'phone',
  'phone02' = 'phone02',
  'phonetic' = 'phonetic',
  'phonetic02' = 'phonetic02',
  'phoneticFilled' = 'phoneticFilled',
  'play' = 'play',
  'playArrow' = 'playArrow',
  'playArrow_1' = 'playArrow_1',
  'playArrowFilled' = 'playArrowFilled',
  'plus' = 'plus',
  'presentationalChart01' = 'presentationalChart01',
  'presentationChart03' = 'presentationChart03',
  'public' = 'public',
  'publicFilled' = 'publicFilled',
  'puzzlePiece02' = 'puzzlePiece02',
  'recording02' = 'recording02',
  'refresh' = 'refresh',
  'replay' = 'replay',
  'reverse-left' = 'reverse-left',
  'reverse-right' = 'reverse-right',
  'script-select' = 'script-select',
  'script' = 'script',
  'search' = 'search',
  'send' = 'send',
  'sendFilled' = 'sendFilled',
  'settings' = 'settings',
  'settings01' = 'settings01',
  'settingsFilled' = 'settingsFilled',
  'share' = 'share',
  'shareFilled' = 'shareFilled',
  'signal02' = 'signal02',
  'sliders04' = 'sliders04',
  'square' = 'square',
  'stand' = 'stand',
  'stars01' = 'stars01',
  'stars02' = 'stars02',
  'stars03' = 'stars03',
  'studio' = 'studio',
  'team' = 'team',
  'team_1' = 'team_1',
  'teamFilled' = 'teamFilled',
  'tool02' = 'tool02',
  'toolbar' = 'toolbar',
  'trash' = 'trash',
  'tv03' = 'tv03',
  'type' = 'type',
  'typeStrikethrough' = 'typeStrikethrough',
  'upload' = 'upload',
  'user02' = 'user02',
  'userCircle' = 'userCircle',
  'userPlus' = 'userPlus',
  'users01' = 'users01',
  'users03' = 'users03',
  'usersPlus' = 'usersPlus',
  'visibility' = 'visibility',
  'visibilityFilled' = 'visibilityFilled',
  'visibilityOff' = 'visibilityOff',
  'visibilityOffFilled' = 'visibilityOffFilled',
  'voices' = 'voices',
  'warning' = 'warning',
  'warningCircle' = 'warningCircle',
  'warningFilled' = 'warningFilled',
  'workflow' = 'workflow',
  'xCircle' = 'xCircle',
  'xSm' = 'xSm',
}

export type AvailableIconTypes = keyof typeof IconType;
